.nav__container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(252, 252, 252, 0.93);
    position: fixed;
    height: 100vh;
    width: 350px;
    top: 0;
    right: 0;
    z-index: 10000;
    animation: x 1s;
}

@keyframes x {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transition: all 0.5s;
    }
}

.images {
    width: 40px;
    z-index: 10001;
    cursor: pointer;
}

.nav__container a {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    padding: 1rem;
    border: 1px solid #6b10e0;
    border-radius: 12px;
    transition: 250ms ease-in-out;
}

.nav__container a:hover {
    transform: scale(1.2);
}
