@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-color: #653981;
  --white: #fff;
  --transition: 250ms ease;
}

body {
  font-family: 'DM Sans', sans-serif;
}

button {
  cursor: pointer;
  text-transform: uppercase;
  font-family: inherit;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/*LeafletContainer*/

.leaflet-popup-content-wrapper {
  min-width: 340px !important;
}

.success {
  background-color: #f1eeee;
  height: calc(100vh - 10vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  padding: 10px;
}